import React, { useEffect, useState } from 'react';
import './Vesting.css'
import { GoArrowLeft } from 'react-icons/go';
import { useNavigate, useParams } from 'react-router-dom';
import StartTimeIcon from '../../assets/start-time-icon.png'
import EndTimeIcon from '../../assets/end-time-icon.png'
import BeneficiaryIcon from '../../assets/beneficiary_icon.png'
import ReleasableIcon from '../../assets/releasable_token_icon.png'
import MagnumIcon from '../../assets/vest-details-sec-mag-icon.png'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { useAccount, useChainId, useReadContract, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import VEST_ABI from '../../Utils/ABI/VestingAbi.json'
import { formatEther, parseEther } from 'viem';
import LoaderVesting from '../../Utils/popup/LoaderVesting';
import { Modal } from "react-bootstrap";
import "../../Utils/popup/Popup.css";
import SuccessIcon from "../../assets/modal-success-icon.png";
import NotConnect from './NotConnect';
import { successPopup } from '../../Utils/ToasterMessage';
import Loader from '../../components/Loader/Loader';
import { bigConciseAddress } from '../../Utils/wallet/WalletHook';

export default function VestingDetails() {
    const { data: relaseTokenHash, isSuccess: relaseTokenSuccess, isError: relaseTokenError, writeContract: releaseTokenWrite, error: errorMesssageRelease } = useWriteContract();
    const [loader, setLoader] = useState(false);
    const [loaderDataFetch, setLoaderDataFetch] = useState(true);
    const [vestingDetails, setVestingDetails] = useState([]);
    const [vestHistory, setVestHistory] = useState([]);
    const [refectVesting, setRefectVesting] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [releaseAmount, setReleaseAmount] = useState(0);
    const navigate = useNavigate();
    const { beneficiary } = useParams();
    const { address } = useAccount();
    const chainId = useChainId();

    const handleClose = () => {
        setSuccessModal(false);
        setRefectVesting(true);
    }

    const handleReleaseTokens = () => {
        if (chainId !== Number(process.env.REACT_APP_CHAIN_ID)) {
            successPopup(`Kindly swtich your network to BNB ${process.env.REACT_APP_CHAIN_ID === 56 ? 'Mainnet' : 'Testnet'}!`)
            return;
        }

        if (vestingDetails?.beneficiary !== address) {
            successPopup('You are not beneficiary of this vesting');
            return;
        }

        let startTime = new Date(Number(vestingDetails?.startTime) * 1000);
        let currentTime = new Date();

        if (startTime >= currentTime) {
            successPopup(`Vesting hasn't started yet!`);
            return;
        }

        let specialLockPeriod = new Date((Number(vestingDetails?.startTime) + Number(vestingDetails?.specialLockPeriod)) * 1000);

        if (specialLockPeriod >= currentTime) {
            successPopup(`You can't release token before special lock period!`);
            return;
        }

        setLoader(true)

        try {
            releaseTokenWrite({
                address: process.env.REACT_APP_VESTING_ADDRESS,
                abi: VEST_ABI,
                functionName: !vestingDetails?.isTreasuryToken ? "releaseTokens" : "releaseTokensWithCustomAmount",
                args: !vestingDetails?.isTreasuryToken ? [address] : [address, parseEther('10')]
            });
        } catch (error) {
            console.error(error)
            setLoader(false)
        }
    }

    // wagmi hooks for waiting till the transaction successful and get the stake data
    const {
        data: releaseTokensWaitData,
        isLoading: releaseTokensWaitLoading,
        isSuccess: releaseTokensWaitSuccess,
        isError: releaseTokensWaitError,
        error: errorMesssage
    } = useWaitForTransactionReceipt({
        hash: relaseTokenHash,
    });

    const { data: vestingData, error: vestingFetchError, isSuccess: vestingDataFetchSuccess, refetch } = useReadContract({
        address: process.env.REACT_APP_VESTING_ADDRESS,
        abi: VEST_ABI,
        functionName: 'getVestingSchedule',
        args: [beneficiary],
    })

    const { data: releasableAmount, error: releasableAmountError, isSuccess: releasableAmountSuccess, refetch: refetchReleaseableAmount } = useReadContract({
        address: process.env.REACT_APP_VESTING_ADDRESS,
        abi: VEST_ABI,
        functionName: 'releasableAmountView',
        args: [beneficiary],
    })

    const { data: vestingHistory, error: vestingHistoryError, isSuccess: vestingHistorySuccess } = useReadContract({
        address: process.env.REACT_APP_VESTING_ADDRESS,
        abi: VEST_ABI,
        functionName: 'getReleaseHistory',
        args: [beneficiary],
    })

    function convertUnixToDate(unixTimestamp) {
        const date = new Date(unixTimestamp * 1000);

        // Extracting the parts of the date
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        // Formatting to 12-hour format with AM/PM
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format

        return `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
    }

    useEffect(() => {
        if (refectVesting) {
            refetch()
            refetchReleaseableAmount();
        }

        if (releasableAmountSuccess) {
            setReleaseAmount(formatEther(releasableAmount))
        }

        if (vestingDataFetchSuccess) {
            setVestingDetails(vestingData);
            setLoaderDataFetch(false)

            if (Number(vestingData?.totalAmount) === 0) {
                navigate(-1);
            }
        }

        if (!refectVesting) {
            if (relaseTokenError || releaseTokensWaitError || releaseTokensWaitSuccess) {
                setLoader(false);
            }

            if (relaseTokenHash && (relaseTokenError || releaseTokensWaitError || releaseTokensWaitSuccess)) {
                setSuccessModal(true)
            } else {
                setSuccessModal(false)
            }
        }
    }, [relaseTokenHash, relaseTokenError, releaseTokensWaitError, releaseTokensWaitSuccess, vestingData, vestingDataFetchSuccess, refectVesting, releasableAmountSuccess])

    useEffect(() => {
        let arr = [];

        if (vestingHistory) {
            for (let i = 0; i < vestingHistory.length; i++) {
                arr.push({ amount: formatEther(vestingHistory[i]?.amount), timeStamp: convertUnixToDate(Number(vestingHistory[i]?.timestamp)) })
            }
        }

        function sumAmountsByMonth(data) {
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            const result = data.reduce((acc, { amount, timeStamp }) => {
                // Extract day, month, and year from timeStamp
                const [day, month, year] = timeStamp.split(',')[0].split('-');
                const monthIndex = parseInt(month, 10) - 1; // Convert month to zero-based index
                const monthFormatted = `${monthNames[monthIndex]} ${year.slice(-2)}`; // e.g., "Nov 24"

                // Ensure amount is treated as a number
                amount = Number(amount);

                // Check if monthFormatted already exists in the accumulator
                if (acc[monthFormatted]) {
                    acc[monthFormatted].totalAmount += amount;
                } else {
                    acc[monthFormatted] = { month: monthFormatted, totalAmount: amount };
                }

                return acc;
            }, {});

            // Convert the result back to an array
            return Object.values(result);
        }

        // const data = [
        //     { amount: 1.958333333333333333, timeStamp: '04-11-2024, 6:02 PM' },
        //     { amount: 2.375, timeStamp: '04-11-2024, 6:03 PM' },
        //     { amount: 54.125, timeStamp: '04-11-2024, 6:25 PM' },
        //     { amount: 41.541666666666666667, timeStamp: '06-11-2024, 6:32 PM' },
        //     { amount: 1.111111111111111111, timeStamp: '06-12-2024, 6:48 PM' },
        //     { amount: 6.833333333333333333, timeStamp: '06-12-2024, 6:56 PM' },
        //     { amount: 92.055555555555555556, timeStamp: '07-12-2024, 12:19 PM' },
        //     { amount: 6.833333333333333333, timeStamp: '06-01-2025, 6:56 PM' },
        //     { amount: 72.055555555555555556, timeStamp: '06-01-2025, 12:19 PM' },
        //     { amount: 6.833333333333333333, timeStamp: '06-02-2025, 6:56 PM' },
        //     { amount: 22.055555555555555556, timeStamp: '06-02-2025, 12:19 PM' },
        //     { amount: 92.055555555555555556, timeStamp: '06-03-2025, 12:19 PM' }
        // ];

        setVestHistory(sumAmountsByMonth(arr))
    }, [vestingHistory])

    console.log('vestingHistory', vestHistory);

    if (loaderDataFetch) return <Loader />;

    return (
        <>
            {loader && <LoaderVesting />}
            {!address ? (
                <NotConnect />
            ) : (
                <div className="referralDetails_container">
                    <div className="vest-details-header-cont">
                        <p className='vd-heading'>
                            <span style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
                                <GoArrowLeft fontSize={27} />
                            </span>
                            <span className='ms-3'>{vestingDetails?.tokenType}</span>
                        </p>

                        <button className='relase-token-btn' onClick={() => handleReleaseTokens()}>Release Token</button>
                    </div>

                    <div className='vd-cont'>
                        <div className='vdc-box-cont'>
                            <div className='vd-box1'>
                                <img src={StartTimeIcon} alt='clock-icon' height={22} width={22} />

                                <p><span>Start Date:</span> {convertUnixToDate(Number(vestingDetails?.startTime))}</p>
                            </div>

                            <div className='vd-box1'>
                                <img src={EndTimeIcon} alt='clock-icon' height={22} width={22} />

                                <p><span>End Date:</span> {convertUnixToDate(Number(vestingDetails?.startTime) + Number(vestingDetails?.duration))}</p>
                            </div>
                        </div>

                        <div className='vdc-box-cont'>
                            <div className='vd-box1'>
                                <img src={BeneficiaryIcon} alt='beneficiary-icon' height={22} width={22} />

                                <p><span>Beneficiary:</span> {bigConciseAddress(vestingDetails?.beneficiary)}</p>
                            </div>

                            <div className='vd-box1'>
                                <img src={ReleasableIcon} alt='token-icon' height={22} width={22} />

                                <p><span>Releasable Token:</span> {Number(releaseAmount).toFixed(2) || 0}</p>
                            </div>
                        </div>

                        <div className='vdc-box-cont'>
                            <div className='vb-box2-outer'>
                                <p>Vested Token</p>
                                <div className='vb-box2'>
                                    <p>{formatEther(vestingDetails?.totalAmount ? vestingDetails?.totalAmount : '0')}</p>

                                    <img src={MagnumIcon} alt='magnum-icon' />
                                </div>
                            </div>

                            <div className='vb-box2-outer'>
                                <p>Claimed Token</p>
                                <div className='vb-box2'>
                                    <p>{Number(formatEther(vestingDetails?.releasedAmount ? vestingDetails?.releasedAmount : '0')).toFixed(2)}</p>

                                    <img src={MagnumIcon} alt='magnum-icon' />
                                </div>
                            </div>

                            <div className='vb-box2-outer'>
                                <p>Remaining Token</p>
                                <div className='vb-box2'>
                                    <p>{Number(formatEther(vestingDetails?.totalAmount ? vestingDetails?.totalAmount : '0') - formatEther(vestingDetails?.releasedAmount ? vestingDetails?.releasedAmount : '0')).toFixed(2)}</p>

                                    <img src={MagnumIcon} alt='magnum-icon' />
                                </div>
                            </div>
                        </div>

                        <div className='vdc-box-cont'>
                            <div className='vb-box2-outer'>
                                <p>Special Lock</p>
                                <div className='vb-box2'>
                                    <p>{vestingDetails?.isSpecialLock === true ? 'Yes' : 'No'}</p>
                                </div>
                            </div>

                            <div className='vb-box2-outer'>
                                <p>Initial Lock</p>
                                <div className='vb-box2'>
                                    <p>{Number(vestingDetails?.initialUnlock ? vestingDetails?.initialUnlock : '0')} %</p>
                                </div>
                            </div>

                            <div className='vb-box2-outer'>
                                <p>Special Lock Duration</p>
                                <div className='vb-box2'>
                                    <p>{vestingDetails?.specialLockPeriod ? (Number(vestingDetails?.specialLockPeriod) / 2629743) : '0'} Months</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {vestHistory?.length > 0 && (
                        <div className='vd-cont'>
                            <div className='vdc-bar-heading'>PROCESS</div>

                            <div className='vd-chart-cont'>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        width={500}
                                        height={300}
                                        data={vestHistory}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid horizontal={true} vertical={false} />
                                        <XAxis dx={10} tick={{ fill: '#000000' }} tickLine={false} axisLine={false} dataKey="month" />
                                        <YAxis axisLine={false} tickLine={false} />
                                        {/* <Tooltip /> */}
                                        <Line type="monotoneX" dataKey="totalAmount" stroke="#AC8642" strokeWidth={'3px'} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {/* Modal */}
            <Modal
                className="modal_center"
                show={successModal}
                onHide={handleClose}
                centered>
                <Modal.Body className="mdl-bdy">
                    {releaseTokensWaitSuccess ? (
                        <>
                            <img src={SuccessIcon} alt="success-icon" />

                            <p className="succ-para">Release Successfully</p>

                            <button
                                className="modal-btn"
                                onClick={() =>
                                    window.open(
                                        `https://testnet.bscscan.com/tx/${relaseTokenHash}`,
                                        "_blank"
                                    )
                                }>
                                View transaction on BscScan
                            </button>
                        </>
                    ) : (
                        <>
                            <div className="fail-symbol">x</div>
                            <>
                                <p className="fail-para-head">Vesting Transaction Failed</p>
                                <p className="fail-para">
                                    Vesting transaction failed. Please try again or contact support
                                    if the issue persists.
                                </p>
                            </>
                        </>
                    )}

                    <button className="modal-btn" onClick={handleClose}>
                        Close
                    </button>
                </Modal.Body>
            </Modal>
        </>
    );
}
