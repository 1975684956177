// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.button-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader_for_btn{
    border: 3px solid rgba(0, 0, 0, 0.54);
    border-top: 3px solid #ffffff;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    animation: spin 1s linear infinite;
    transform: translate(-50%, -50%);
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }`, "",{"version":3,"sources":["webpack://./src/Utils/Loader/ButtonLoader.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,qCAAqC;IACrC,6BAA6B;IAC7B,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;IAClC,gCAAgC;EAClC;;EAEA;IACE;MACE,uBAAuB;IACzB;;IAEA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":["\n.button-loader-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .loader_for_btn{\n    border: 3px solid rgba(0, 0, 0, 0.54);\n    border-top: 3px solid #ffffff;\n    border-radius: 50%;\n    width: 22px;\n    height: 22px;\n    animation: spin 1s linear infinite;\n    transform: translate(-50%, -50%);\n  }\n  \n  @keyframes spin {\n    0% {\n      transform: rotate(0deg);\n    }\n  \n    100% {\n      transform: rotate(360deg);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
