import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import './Sidebar.css'
import magnummetaLogo from '../../../assets/magnumMetaLogo.png'
import homeIcon from '../../../assets/home.png'
import migrationBlueIcon from '../../../assets/migrationBlueIcon.png'
import stakingIcon from '../../../assets/staking.png'
import DAOIcon from '../../../assets/DAO.png'
import vestingIcon from '../../../assets/vesting.png'
import migrationIcon from '../../../assets/migration.png'
import homeBlueIcon from '../../../assets/homeBlue.png'
import stakingBlueIcon from '../../../assets/stakingBlue.png'
import vestingBlueIcon from '../../../assets/vestingBlue.png'
import daoBlueIcon from '../../../assets/daoBlue.png'
import UserContext from '../../ContextApi/UserContext'
import newsIcon from '../../../assets/newsIcon.png'
import newBlueIcon from '../../../assets/newBlueIcon.png'
import { IoMdClose } from "react-icons/io";
import settingBlueIcon from '../../../assets/settingBlue.png'
import affilateIcon from '../../../assets/affilate.png'
import affilateBlueIcon from '../../../assets/affilateBlue.png'
import directReward from "../../../assets/direct_reward.png";
import directRewardActive from "../../../assets/direct_reward_active.png";
import directTeam from "../../../assets/direct_team.png";
import directTeamActive from "../../../assets/direct_team_active.png";
import indirectTeam1 from "../../../assets/indirect_team.png";
import indirectTeamActive from "../../../assets/indirect_team_active.png";
import leadershipRank1 from "../../../assets/leadership_rank.png";
import leadershipRankActive from "../../../assets/leadership_rank_active.png";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { MdRoomPreferences } from 'react-icons/md'
import { FaMoneyBillAlt } from 'react-icons/fa'


export default function SideBar() {

  const { toggleMenu, setToggleMenu } = useContext(UserContext)

  const location = useLocation();
  const [showAffiliateDropdown, setShowAffiliateDropdown] = useState(false)
  const [showFarmDropdown, setShowFarmDropdown] = useState(false)
  const [showVestDropdown, setShowVestDropdown] = useState(false)

  const navbarItems = [
    {
      name: 'home',
      path: '/home',
      icon: homeIcon,
      activeIcon: homeBlueIcon
    },
    {
      name: 'migration',
      path: '/migration',
      icon: migrationIcon,
      activeIcon: migrationBlueIcon
    },
    {
      name: 'farm',
      path: '/farming',
      icon: stakingIcon,
      activeIcon: stakingBlueIcon,
      dropdownFarm: [
        { name: 'Fetch Stake', path: '/farming/fetch-stake', icon: directReward, activeIcon: directRewardActive },
      ]
    },
    {
      name: 'affiliate',
      path: '/affiliate/referral-requests',
      icon: affilateIcon,
      activeIcon: affilateBlueIcon,
      dropdown: [
        { name: 'Leaderboard Rank', path: '/affiliate/leaderboard-rank', icon: directReward, activeIcon: directRewardActive },
        { name: 'Personal Invite', path: '/affiliate/personal-invite', icon: directTeam, activeIcon: directTeamActive },
        { name: 'Secondary Invite', path: '/affiliate/secondary-invite', icon: indirectTeam1, activeIcon: indirectTeamActive },
        { name: 'Leaderboard Matrix', path: '/affiliate/leaderboard-matrix', icon: leadershipRank1, activeIcon: leadershipRankActive },
        { name: 'Referral Requests', path: '/affiliate/referral-requests', iconImg: <MdRoomPreferences style={{ marginRight: '6px', color: '#6b7280' }} size={25} />, activeIconImg: <MdRoomPreferences style={{ marginRight: '6px', color: '#234b9a' }} size={25} /> },
        { name: 'Referal Reward', path: '/affiliate/referral-reward', iconImg: <FaMoneyBillAlt style={{ marginRight: '6px', color: '#6b7280' }} size={25} />, activeIconImg: <FaMoneyBillAlt style={{ marginRight: '6px', color: '#234b9a' }} size={25} /> },
      ]
    },
    {
      name: 'vesting',
      path: '/vesting',
      icon: vestingIcon,
      activeIcon: vestingBlueIcon,
      dropdownVest: [
        { name: 'Add Vesting', path: '/vesting/add-vesting', icon: directReward, activeIcon: directRewardActive },
      ]
    },

    {
      name: 'dao',
      path: '/dao',
      icon: DAOIcon,
      activeIcon: daoBlueIcon,
    },
    {
      name: 'news',
      path: '/news',
      icon: newsIcon,
      activeIcon: newBlueIcon,
    },
  ]

  const toggleAffiliateDropdown = () => {
    setShowAffiliateDropdown(!showAffiliateDropdown)
  }

  const toggleFarmDropdown = () => {
    setShowFarmDropdown(!showFarmDropdown)
  }

  const toggleVestingDropdown = () => {
    setShowVestDropdown(!showVestDropdown);
  }


  return (
    <>
      <div className={`sidebar_main ${toggleMenu && 'd-block'}`}>
        <div className='magnumlog_icon'>
          <div className='hideCalnceIcon'>
            <p className='icon_sidebarClose m-0 '><span onClick={() => setToggleMenu(false)}><IoMdClose fontSize={25} /></span></p>
          </div>
          <img src={magnummetaLogo} alt="magnummeta Logo" />
        </div>
        <Nav className='nav_sidebar d-flex flex-column'>
          {
            navbarItems.map((item, index) => (
              <div key={index}>
                <Link to={`${item.path}`} onClick={() => item.name === 'affiliate' ? toggleAffiliateDropdown() : item.name === 'farm' ? toggleFarmDropdown() : item.name === 'vesting' ? toggleVestingDropdown() : setToggleMenu(false)} className={`${(location.pathname.includes(item.path) || (item.name === 'affiliate' && location.pathname.includes('affiliate')) || (item.name === 'farm' && location.pathname.includes('farming')) || (item.name === 'vesting' && location.pathname.includes('vesting'))) && 'active_nav'}`}>
                  <div>
                    <img src={location?.pathname.includes(item.path) || (item.name === 'affiliate' && location.pathname.includes('affiliate')) ? item.activeIcon : item.icon} alt={`${item.name} icon`} />
                    <span className='ms-3'>{item.name}</span>
                  </div>
                  <div className=''>
                    {item.name === 'affiliate' && (
                      <span className='dropdown-arrow '>
                        {showAffiliateDropdown ? <IoMdArrowDropup size={20} /> : <IoMdArrowDropdown size={20} />}
                      </span>
                    )}
                    {item.name === 'farm' && (
                      <span className='dropdown-arrow '>
                        {showFarmDropdown ? <IoMdArrowDropup size={20} /> : <IoMdArrowDropdown size={20} />}
                      </span>
                    )}
                    {item.name === 'vesting' && (
                      <span className='dropdown-arrow '>
                        {showVestDropdown ? <IoMdArrowDropup size={20} /> : <IoMdArrowDropdown size={20} />}
                      </span>
                    )}
                  </div>

                </Link>
                {
                  item.dropdown && showAffiliateDropdown && (
                    <div className="dropdown-side">
                      {item.dropdown.map((dropdownItem, idx) => {
                        return (
                          <Link key={idx} to={dropdownItem.path} onClick={() => setToggleMenu(false)} className={`dropdown-item-side ${location.pathname.includes(dropdownItem.path) && 'active_nav'}`}>

                            {
                              dropdownItem.icon ?
                                <img className='me-2' src={location.pathname === dropdownItem.path ? dropdownItem.activeIcon : dropdownItem.icon} alt={dropdownItem.icon + index} />

                                :
                                location.pathname === dropdownItem.path ? dropdownItem.activeIconImg : dropdownItem.iconImg
                            }
                            <span> {dropdownItem.name}</span>
                          </Link>
                        )
                      }
                      )}
                    </div>
                  )
                }
                {
                  item.dropdownFarm && showFarmDropdown && (
                    <div className="dropdown-side">
                      {item.dropdownFarm.map((dropdownItem, idx) => {
                        return (
                          <Link key={idx} to={dropdownItem.path} onClick={() => setToggleMenu(false)} className={`dropdown-item-side ${location.pathname.includes(dropdownItem.path) && 'active_nav'}`}>

                            {
                              dropdownItem.icon ?
                                <img className='me-2' src={location.pathname === dropdownItem.path ? dropdownItem.activeIcon : dropdownItem.icon} alt={dropdownItem.icon + index} />

                                :
                                location.pathname === dropdownItem.path ? dropdownItem.activeIconImg : dropdownItem.iconImg
                            }
                            <span> {dropdownItem.name}</span>
                          </Link>
                        )
                      }
                      )}
                    </div>
                  )
                }
                {
                  item.dropdownVest && showVestDropdown && (
                    <div className="dropdown-side">
                      {item.dropdownVest.map((dropdownItem, idx) => {
                        return (
                          <Link key={idx} to={dropdownItem.path} onClick={() => setToggleMenu(false)} className={`dropdown-item-side ${location.pathname.includes(dropdownItem.path) && 'active_nav'}`}>

                            {
                              dropdownItem.icon ?
                                <img className='me-2' src={location.pathname === dropdownItem.path ? dropdownItem.activeIcon : dropdownItem.icon} alt={dropdownItem.icon + index} />

                                :
                                location.pathname === dropdownItem.path ? dropdownItem.activeIconImg :  dropdownItem.iconImg
                            }
                            <span> {dropdownItem.name}</span>
                          </Link>
                        )
                      }
                      )}
                    </div>
                  )
                }
              </div>
            ))
          }
        </Nav>
      </div>
    </>
  )
}