export const conciseAddress = (address, startSlice = 4, endSlice = 4) => {
    if (address) {
      return `${address.slice(0, startSlice)}...${address.slice(
        address?.length - endSlice,
        address?.length
      )}`;
    }
    return '';
  };


  export const bigConciseAddress = (address, startSlice = 7, endSlice = 7) => {
    if (address) {
      return `${address.slice(0, startSlice)}....${address.slice(
        address?.length - endSlice,
        address?.length
      )}`;
    }
    return '';
  };