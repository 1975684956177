import React, { useEffect, useState } from 'react'
import './Vesting.css';
import ClockIcon from '../../assets/clockIcon.png';
import { useAccount, useReadContract, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import VEST_ABI from '../../Utils/ABI/VestingAbi.json'
import MFORGE_ABI from '../../Utils/ABI/MforgeAbi.json'
import { parseEther } from 'viem';
import SuccessPopup from '../../Utils/popup/SuccessPopup';
import LoaderVesting from '../../Utils/popup/LoaderVesting';
import { successPopup } from '../../Utils/ToasterMessage';
import NotConnect from './NotConnect';

const AddVesting = () => {
  const [vestDetails, setVestDetails] = useState({
    tokenName: '',
    beneficiary: '',
    startTime: '',
    VestingDuration: 0,
    totalToken: '',
    isSpecialLock: false,
    initialUnlock: '',
    specialLockPeriod: '',
    isTreasuryToken: false
  });
  const [inputError, setInputError] = useState({});
  const [formattedData, setFormatedData] = useState({
    startTime: '',
    vestingDuration: '',
    specialLockPeriod: ''
  });
  const [minDateTime, setMinDateTime] = useState('');
  const [loader, setLoader] = useState(false);
  const [popupData, setPopupData] = useState({});
  const { address } = useAccount();
  const { data: vestingHash, isSuccess: vestingSuccess, isError: vestingError, writeContract: vestingWrite } = useWriteContract();

  const handleChange = (field, value) => {
    setVestDetails((prevState) => ({ ...prevState, [field]: value }))

    if (field === "tokenName") {
      if (!value) {
        setInputError((prevState) => ({ ...prevState, [field]: "Token Name is required!" }));
      } else {
        setInputError((prevState) => ({ ...prevState, [field]: "" }));
      }
    }

    if (field === "VestingDuration" && !vestDetails?.isTreasuryToken) {
      if (!value) {
        setInputError((prevState) => ({ ...prevState, [field]: "Vesting duration is required!" }));
      } else if (value <= 0) {
        setInputError((prevState) => ({ ...prevState, [field]: "Vesting duration can't be zero!" }));
      } else {
        setInputError((prevState) => ({ ...prevState, [field]: "" }));
      }
    }

    if (field === "totalToken") {
      if (!value) {
        setInputError((prevState) => ({ ...prevState, [field]: "Total Token is required!" }));
      } else if (value <= 0) {
        setInputError((prevState) => ({ ...prevState, [field]: "Total Token can't be zero!" }));
      } else {
        setInputError((prevState) => ({ ...prevState, [field]: "" }));
      }
    }

    if (vestDetails?.isSpecialLock) {
      if (field === "initialUnlock") {
        if (!value) {
          setInputError((prevState) => ({ ...prevState, [field]: "Initial Unlock is required!" }));
        } else if (value <= 0) {
          setInputError((prevState) => ({ ...prevState, [field]: "Initial Unlock can't be zero!" }));
        } else if (value > 100) {
          setInputError((prevState) => ({ ...prevState, [field]: "Initial Unlock can't be greater than the 100!" }));
        } else {
          setInputError((prevState) => ({ ...prevState, [field]: "" }));
        }
      }

      if (field === "specialLockPeriod") {
        if (!value) {
          setInputError((prevState) => ({ ...prevState, [field]: "Special Lock Period is required!" }));
        } else if (value <= 0) {
          setInputError((prevState) => ({ ...prevState, [field]: "Special Lock Period can't be zero!" }));
        } else {
          setInputError((prevState) => ({ ...prevState, [field]: "" }));
        }
      }
    } else {
      if (field === "initialUnlock") {
        setInputError((prevState) => ({ ...prevState, [field]: "" }));
      }

      if (field === "specialLockPeriod") {
        setInputError((prevState) => ({ ...prevState, [field]: "" }));
      }
    }

    const validAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;

    if (field === "beneficiary") {
      if (!value) {
        setInputError((prevState) => ({ ...prevState, [field]: "Beneficiary field is required!" }));
      } else if (!value.match(validAddressRegex)) {
        setInputError((prevState) => ({ ...prevState, [field]: "Beneficiary address is not correct!" }));
      } else {
        setInputError((prevState) => ({ ...prevState, [field]: "" }));
      }
    }
  }

  useEffect(() => {
    const getCurrentDateTime = () => {
      const now = new Date();
      now.setMinutes(now.getMinutes() + 10);
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    setMinDateTime(getCurrentDateTime());
  }, []);


  function convertToUnixTimestamp(dateString) {
    const date = new Date(dateString);
    return Math.floor(date.getTime() / 1000);
  }

  useEffect(() => {
    let unixTime = convertToUnixTimestamp(vestDetails?.startTime)
    setFormatedData((prevState) => ({ ...prevState, startTime: unixTime }));

    // let oneMonthUnixTime = 2629743; // 1 month time from in seconds
    let oneMonthUnixTime = 60; // 1 minute time from in seconds

    let unixVestingDuration = vestDetails?.VestingDuration * oneMonthUnixTime;
    setFormatedData((prevState) => ({ ...prevState, vestingDuration: unixVestingDuration }))

    let unixSpecialLockPeriod = vestDetails?.specialLockPeriod * oneMonthUnixTime;
    setFormatedData((prevState) => ({ ...prevState, specialLockPeriod: unixSpecialLockPeriod }))

  }, [vestDetails?.startTime, vestDetails?.VestingDuration, vestDetails?.specialLockPeriod])

  const { data: vestingOwner, error: vestingOwnerError, isSuccess: vestingOwnerSuccess, refetch } = useReadContract({
    address: process.env.REACT_APP_VESTING_ADDRESS,
    abi: VEST_ABI,
    functionName: 'owner',
    args: [],
  })

  const { data: isVestingBeneficiary, error: isVestingBeneficiaryError, isSuccess: isVestingBeneficiarySuccess } = useReadContract({
    address: process.env.REACT_APP_VESTING_ADDRESS,
    abi: VEST_ABI,
    functionName: 'isVestingBeneficiary',
    args: [address],
  })

  const { data: tokenBalance, error: tokenBalanceError, isSuccess: tokenBalanceSuccess } = useReadContract({
    address: process.env.REACT_APP_MFORGE_ADDRESS,
    abi: MFORGE_ABI,
    functionName: 'balanceOf',
    args: [address],
  })

  const { data: vestingTokenBalance, error: vestingTokenBalanceError, isSuccess: vestingTokenBalanceSuccess } = useReadContract({
    address: process.env.REACT_APP_MFORGE_ADDRESS,
    abi: MFORGE_ABI,
    functionName: 'balanceOf',
    args: [process.env.REACT_APP_VESTING_ADDRESS],
  })

  const handleVesting = () => {
    let isValid = true;

    if (!address) {
      successPopup('Kindly connect your wallet first!')
      return;
    }

    if (vestingOwner !== address) {
      successPopup(`You can't vest, kindly switch to the owner wallet`)
      return;
    }

    if (!vestDetails?.tokenName) {
      setInputError((prevState) => ({ ...prevState, tokenName: "Token Name is required!" }));
      isValid = false;
    } else {
      setInputError((prevState) => ({ ...prevState, tokenName: "" }));
      isValid = true;
    }

    const validAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;

    if (!vestDetails?.beneficiary) {
      setInputError((prevState) => ({ ...prevState, beneficiary: "Beneficiary field is required!" }));
      isValid = false;
    } else if (!vestDetails?.beneficiary.match(validAddressRegex)) {
      setInputError((prevState) => ({ ...prevState, beneficiary: "Beneficiary address is not correct!" }));
      isValid = false;
    } else if (vestDetails?.beneficiary.match(isVestingBeneficiary)) {
      successPopup('Beneficiary already exists!')
      isValid = false;
    } else {
      setInputError((prevState) => ({ ...prevState, beneficiary: "" }));
      isValid = true;
    }

    if (!vestDetails?.startTime) {
      setInputError((prevState) => ({ ...prevState, startTime: "Start time is required!" }));
      isValid = false;
    } else {
      setInputError((prevState) => ({ ...prevState, startTime: "" }));
      isValid = true;
    }

    if (!vestDetails?.isTreasuryToken) {
      if (!vestDetails?.VestingDuration) {
        setInputError((prevState) => ({ ...prevState, VestingDuration: "Vesting duration is required!" }));
        isValid = false;
      } else if (vestDetails?.VestingDuration <= 0) {
        setInputError((prevState) => ({ ...prevState, VestingDuration: "Vesting duration can't be zero!" }));
        isValid = false;
      } else {
        setInputError((prevState) => ({ ...prevState, VestingDuration: "" }));
        isValid = true;
      }
    }

    if (!vestDetails?.totalToken) {
      setInputError((prevState) => ({ ...prevState, totalToken: "Total Token is required!" }));
      isValid = false;
    } else if (vestDetails?.totalToken <= 0) {
      setInputError((prevState) => ({ ...prevState, totalToken: "Total Token can't be zero!" }));
      isValid = false;
    } else if (vestDetails?.totalToken > Number(tokenBalance)) {
      successPopup("You don't have Sufficient token!");
      isValid = false;
    } else if (vestDetails?.totalToken > Number(vestingTokenBalance)) {
      successPopup("Vesting contract doesn't hold the sufficient token!");
      isValid = false;
    } else {
      setInputError((prevState) => ({ ...prevState, totalToken: "" }));
      isValid = true;
    }

    if (vestDetails?.isSpecialLock) {
      if (!vestDetails?.initialUnlock) {
        setInputError((prevState) => ({ ...prevState, initialUnlock: "Initial Unlock is required!" }));
        isValid = false;
      } else if (vestDetails?.initialUnlock <= 0) {
        setInputError((prevState) => ({ ...prevState, initialUnlock: "Initial Unlock can't be zero!" }));
        isValid = false;
      } else if (vestDetails?.initialUnlock > 100) {
        setInputError((prevState) => ({ ...prevState, initialUnlock: "Initial Unlock can't greater than 100!" }));
        isValid = false;
      } else {
        setInputError((prevState) => ({ ...prevState, initialUnlock: "" }));
        isValid = true;
      }

      if (!vestDetails?.specialLockPeriod) {
        setInputError((prevState) => ({ ...prevState, specialLockPeriod: "Special Lock Period is required!" }));
        isValid = false;
      } else if (vestDetails?.specialLockPeriod <= 0) {
        setInputError((prevState) => ({ ...prevState, specialLockPeriod: "Special Lock Period can't be zero!" }));
        isValid = false;
      } else if (Number(vestDetails?.specialLockPeriod) >= Number(vestDetails?.VestingDuration)) {
        setInputError((prevState) => ({ ...prevState, specialLockPeriod: "Special Lock Period can't more than the vesting duration!" }));
        isValid = false;
      } else {
        setInputError((prevState) => ({ ...prevState, specialLockPeriod: "" }));
        isValid = true;
      }
    } else {
      setInputError((prevState) => ({ ...prevState, initialUnlock: "" }));

      setInputError((prevState) => ({ ...prevState, specialLockPeriod: "" }));
    }
    
    if (isValid) {
      setLoader(true)
      try {
        vestingWrite({
          address: process.env.REACT_APP_VESTING_ADDRESS,
          abi: VEST_ABI,
          functionName: "addVestingSchedule",
          args: [
            vestDetails?.beneficiary,
            formattedData?.startTime,
            formattedData?.vestingDuration,
            vestDetails?.initialUnlock ? vestDetails?.initialUnlock : '0',
            parseEther(vestDetails?.totalToken ? vestDetails?.totalToken : '0'),
            vestDetails?.isSpecialLock,
            formattedData?.specialLockPeriod,
            vestDetails?.tokenName,
            vestDetails?.isTreasuryToken
          ],
        });
      } catch (error) {
        console.error(error)
        setLoader(false)
      }
    }
  }

  // wagmi hooks for waiting till the transaction successful and get the stake data
  const {
    data: vestingWaitData,
    isLoading: vestingWaitLoading,
    isSuccess: vestingWaitSuccess,
    isError: vestingWaitError
  } = useWaitForTransactionReceipt({
    hash: vestingHash,
  });

  useEffect(() => {
    let data = {
      txnHash: vestingHash,
      success: vestingWaitSuccess,
      vestingError: vestingError,
      vestingWaitError: vestingWaitError
    }

    setPopupData(data)

    if (vestingWaitSuccess || vestingError || vestingWaitError) {
      setLoader(false);
    }

    if (vestingWaitSuccess) {
      setVestDetails({
        tokenName: '',
        beneficiary: '',
        startTime: '',
        VestingDuration: 0,
        totalToken: '',
        isSpecialLock: false,
        initialUnlock: '',
        specialLockPeriod: '',
        isTreasuryToken: false
      })
    }

  }, [vestingHash, vestingError, vestingWaitError, vestingWaitSuccess]);

  return (
    <>
      <SuccessPopup popupData={popupData} />
      {loader && <LoaderVesting />}
      {!address ? (
        <NotConnect />
      ) : (
        <div className='add-vesting-outer-cont'>
          <div className='add-vesting-inner-cont'>
            <div className='av-head-cont'>
              <h4 className='av-heading'>Add Vesting</h4>

              <div className='avhc-tresaury-cont'>
                <h4 className='av-heading'>Treasury Token</h4>

                <label className="switch">
                  <input type="checkbox" name='isTreasuryToken' checked={vestDetails?.isTreasuryToken} onChange={(e) => handleChange(e.target.name, !vestDetails?.isTreasuryToken)} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>

            <div className='add-vesting-input-content-cont'>
              <div>
                <p>Token Name</p>

                <input className='add-vesting-input' type='text' name='tokenName' placeholder='Enter token name' value={vestDetails?.tokenName} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                {inputError?.tokenName && <span className='input-error'>{inputError?.tokenName}</span>}
              </div>

              <div>
                <p>Beneficiary</p>

                <input className='add-vesting-input' type='text' name='beneficiary' placeholder='Wallet Address' value={vestDetails?.beneficiary} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                {inputError?.beneficiary && <span className='input-error'>{inputError?.beneficiary}</span>}
              </div>

              <div>
                <p>Start Time</p>

                <section style={{ position: 'relative' }}>
                  <input
                    className='add-vesting-input av-date-input'
                    type='datetime-local'
                    name='startTime'
                    placeholder='Select Start Time'
                    min={minDateTime}
                    value={vestDetails?.startTime}
                    step={'2'}
                    onChange={(e) => {
                      const selectedDateTime = new Date(e.target.value);
                      const currentDateTime = new Date(minDateTime);

                      const today = new Date();
                      const isToday = selectedDateTime.toDateString() === today.toDateString();

                      if (isToday && selectedDateTime < currentDateTime) {
                        setInputError((prevState) => ({ ...prevState, [e.target.name]: "Please select the valid time!" }));
                        setVestDetails((prevState) => ({ ...prevState, [e.target.name]: '' }))
                        e.preventDefault();
                        return;
                      } else {
                        setInputError((prevState) => ({ ...prevState, [e.target.name]: "" }));
                      }

                      handleChange(e.target.name, e.target.value);
                    }}
                    onClick={(e) => e.target.showPicker()}
                    onKeyDown={(e) => e.preventDefault()}
                  />

                  <span
                    onClick={() => document.getElementById('startTimeInput').showPicker()}
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                      pointerEvents: 'none',
                      background: '#234B9A1A',
                      borderRadius: '6px',
                      padding: '5px'
                    }}
                  >
                    <img src={ClockIcon} alt="calendar icon" style={{ width: '20px', height: '20px' }} />
                  </span>
                </section>
                {inputError?.startTime && <span className='input-error'>{inputError?.startTime}</span>}
              </div>

              {!vestDetails?.isTreasuryToken && (
                <div>
                  <p>Vesting Duration (Months)</p>

                  <input className='add-vesting-input' type='number' name='VestingDuration' placeholder='Enter vesting duration (in numbers)' value={vestDetails?.VestingDuration} onChange={(e) => handleChange(e.target.name, e.target.value)} onWheel={(e) => e.target.blur()} />
                  {inputError?.VestingDuration && <span className='input-error'>{inputError?.VestingDuration}</span>}
                </div>
              )}

              <div>
                <p>Total Token</p>

                <input className='add-vesting-input' type='number' name='totalToken' placeholder='Enter Total Amount' value={vestDetails?.totalToken} onChange={(e) => handleChange(e.target.name, e.target.value)} onWheel={(e) => e.target.blur()} />
                {inputError?.totalToken && <span className='input-error'>{inputError?.totalToken}</span>}
              </div>

              {!vestDetails?.isTreasuryToken && (
                <>
                  <div>
                    <p>Special Lock</p>

                    <div className='av-radio-u-cont'>
                      <div className='av-radio-cont'>
                        <input className='add-vesting-radio' type='radio' name='isSpecialLock' value='true' onChange={(e) => handleChange(e.target.name, e.target.value === 'true' ? true : false)} />
                        <label>True</label>
                      </div>

                      <div className='av-radio-cont'>
                        <input className='add-vesting-radio' type='radio' name='isSpecialLock' value='false' defaultChecked onChange={(e) => handleChange(e.target.name, e.target.value === 'false' ? false : true)} />
                        <label>False</label>
                      </div>
                    </div>
                  </div>

                  <div style={{ display: `${vestDetails?.isSpecialLock ? 'block' : 'none'}` }}>
                    <div style={{ marginBottom: '25px' }}>
                      <p>Initial Unlock (%)</p>

                      <input max={100} maxLength={100} className='add-vesting-input' type='number' name='initialUnlock' placeholder='Initial unlock (in percent)' value={vestDetails?.initialUnlock} onChange={(e) => handleChange(e.target.name, e.target.value)} onWheel={(e) => e.target.blur()} />
                      {inputError?.initialUnlock && <span className='input-error'>{inputError?.initialUnlock}</span>}
                    </div>

                    <div>
                      <p>Special Lock Period (Months)</p>

                      <input className='add-vesting-input' type='number' name='specialLockPeriod' placeholder='Enter Special Lock Period' value={vestDetails?.specialLockPeriod} onChange={(e) => handleChange(e.target.name, e.target.value)} onWheel={(e) => e.target.blur()} />
                      {inputError?.specialLockPeriod && <span className='input-error'>{inputError?.specialLockPeriod}</span>}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className='av-btn-cont'>
              <button className='add-vesting-btn' onClick={handleVesting}>Add Vesting</button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AddVesting;