// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Quicksand";
  height: 100vh;
}

code {
  font-family: "Quicksand";
}
.dekstop-text{
  font-size: 17px;
}

iframe {
  display: none;
}


@media screen and (max-width: 768px) {
  .dekstop-text{
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,wBAAwB;EACxB,aAAa;AACf;;AAEA;EACE,wBAAwB;AAC1B;AACA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;;AAGA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Quicksand\";\n  height: 100vh;\n}\n\ncode {\n  font-family: \"Quicksand\";\n}\n.dekstop-text{\n  font-size: 17px;\n}\n\niframe {\n  display: none;\n}\n\n\n@media screen and (max-width: 768px) {\n  .dekstop-text{\n    font-size: 14px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
