import React, { useState } from 'react'
import UserContext from './UserContext'


export default function UserContextProvider({ children }) {

  const [loginOtpModal, setLoginOtpModal] = useState(false)
  const [toggleMenu, setToggleMenu] = useState(false)
  const [openMigTxModal, setOpenMigTxModal] = useState(false)

  return (
    <UserContext.Provider
      value={{
        loginOtpModal, setLoginOtpModal,
        toggleMenu, setToggleMenu,
        openMigTxModal, setOpenMigTxModal
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
