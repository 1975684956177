import React, { useEffect, useState } from "react";
import "./Popup.css";
import { Modal } from "react-bootstrap";
import SuccessIcon from "../../assets/modal-success-icon.png";

const SuccessPopup = ({ popupData }) => {
  const [successModal, setSuccessModal] = useState(false);
  const handleClose = () => setSuccessModal(false);

  useEffect(() => {
    if (
      popupData?.txnHash &&
      (popupData?.success ||
      popupData?.vestingError ||
      popupData?.vestingWaitError)
    ) {
      setSuccessModal(true);
    } else {
      setSuccessModal(false);
    }
  }, [popupData]);

  return (
    <Modal
      className="modal_center"
      show={successModal}
      onHide={handleClose}
      centered>
      <Modal.Body className="mdl-bdy">
        {popupData?.success ? (
          <>
            <img src={SuccessIcon} alt="success-icon" />

            <p className="succ-para">Vesting Successful</p>

            <button
              className="modal-btn"
              onClick={() =>
                window.open(
                  `https://testnet.bscscan.com/tx/${popupData?.txnHash}`,
                  "_blank"
                )
              }>
              View transaction on BscScan
            </button>
          </>
        ) : (
          <>
            <div className="fail-symbol">x</div>
            <>
              <p className="fail-para-head">Vesting Transaction Failed</p>
              <p className="fail-para">
                Vesting transaction failed. Please try again or contact support
                if the issue persists.
              </p>
            </>
          </>
        )}

        <button className="modal-btn" onClick={handleClose}>
          Close
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessPopup;
