import React from 'react';
import './WalletConnectBtn.css';
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useBalance, useChainId } from 'wagmi';
import { conciseAddress } from './WalletHook';

export default function WalletConnectBtn({ btnColor }) {
    const { open } = useWeb3Modal();
    const { address } = useAccount();
    const walletBalance = useBalance({
        address: address
    });
    const chainId = useChainId();

    return (
        <>
            {!address ?
                <button className={`wallet-connect-btn ${btnColor === 'blue' ? 'wallet-connect-btn-blue' : ''}`} onClick={() => open()}> {'Connect Wallet'}</button>
                :
                <div onClick={() => open()} className={`wallet_btnGroup ${btnColor === 'blue' ? 'wallet_btnGroup_blue' : ''}`}
                    type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                        chainId !== 80002 && chainId !== 1 && chainId !== 56 && chainId !== 137 && chainId !== 97 ?
                            <>
                                <button className='wallet_balance fs-6'>Unsupported Network</button>
                                <button className='walt_add'>{conciseAddress(address)}</button>
                            </> :
                            <>
                                <button className='wallet_balance'>{Number(walletBalance?.data?.formatted).toFixed(4)}{' '}{`${chainId === 137 || chainId === 80002 ? 'MATIC' : chainId === 1 ? 'ETH' : 'BNB'}`}</button>
                                <button className='walt_add'>{conciseAddress(address)}</button>
                            </>
                    }
                </div>
            }
        </>
    )
}
