// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-connect-main-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 60px;
}

.nc-sub-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.nc-sub-cont img {
    margin-bottom: 25px;
}

.ncs-box-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #FFFFFF;
    background: #FFFFFF33;
    box-shadow: 2px 2px 10px 0px #00000024;
    padding: 40px 20px;
    border-radius: 10px;
    width: 50%;
    gap: 12px;
}

.ncs-box-cont > h4 {
    color: #000000;
    font-size: 42px;
    font-weight: 600;
}

.ncs-box-cont > p {
    color: #717171;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 45px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Vesting/NotConnect.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,qBAAqB;IACrB,sCAAsC;IACtC,kBAAkB;IAClB,mBAAmB;IACnB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".not-connect-main-cont {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    padding-top: 60px;\n}\n\n.nc-sub-cont {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    width: 100%;\n}\n\n.nc-sub-cont img {\n    margin-bottom: 25px;\n}\n\n.ncs-box-cont {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    border: 2px solid #FFFFFF;\n    background: #FFFFFF33;\n    box-shadow: 2px 2px 10px 0px #00000024;\n    padding: 40px 20px;\n    border-radius: 10px;\n    width: 50%;\n    gap: 12px;\n}\n\n.ncs-box-cont > h4 {\n    color: #000000;\n    font-size: 42px;\n    font-weight: 600;\n}\n\n.ncs-box-cont > p {\n    color: #717171;\n    font-size: 24px;\n    font-weight: 500;\n    margin-bottom: 45px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
