import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/Auth/Login";
import ForgotPassword from "./components/Auth/ForgotPassword";
import { ToastContainer } from "react-toastify";
import UserContextProvider from "./components/ContextApi/UserContextProvider";
import Migration from "./Pages/Migration/Home";
import Layout from "./Pages/Layout/Layout";
import Vesting from "./Pages/Vesting/Vesting";
import Dao from "./Pages/DAO/Dao";
import Referral from "./Pages/Referral/Referral";
import Home from "./Pages/Home/Home";
import Staking from "./Pages/Staking/Staking";
import Blogs from "./Pages/Blogs/Blogs";
import News from "./Pages/News/News";
import PrivateRoutes from "./Routing/PrivateRoute";
import NewsDetails from "./Pages/News/NewsDetails";
import VideoNews from "./Pages/News/VideoNews";
import EditTextArticle from "./Pages/News/EditTextArticle";
import AddNews from "./Pages/News/AddNews/AddNews";
import EditVideoArticles from "./Pages/News/EditVideoArticles";
import ReferralDetails from "./Pages/Referral/ReferralDetails";
import LeadershipBoard from "./Pages/LeadershipBoard/LeadershipBoard";
import LeadershipBoardDetails from "./Pages/LeadershipBoard/LeadershipBoardDetails";
import DaoDetails from "./Pages/DAO/DaoDetails";
import VestingDetails from "./Pages/Vesting/VestingDetails";
import StakingDetails from "./Pages/Staking/StakingDetails";
import LeadershipRank from "./Pages/LeadershipRank/LeadershipRank";
import LeadershipRankDetails from "./Pages/LeadershipRank/LeadershipRankDetails";
import ReferalReward from "./Pages/ReferalReward";
import ReferalRewardDetails from "./Pages/ReferalReward/Details";
import Direct from "./Pages/Direct";
import DirectDetails from "./Pages/Direct/DirectDetails";
import Indirect from "./Pages/Indirect";
import InDirectDetails from "./Pages/Indirect/IndirectDetails";
import ChangePassword from "./components/Auth/ChangePassword";
import StakingFetch from "./Pages/Staking/StakingFetch";
import AddVesting from "./Pages/Vesting/AddVesting";

function App() {
  return (
    <>
      <UserContextProvider>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Navigate to={"/login"} />} />
          <Route path="*" element={<Navigate to={"/login"} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="" element={<Layout />}>
            <Route path="" element={<PrivateRoutes />}>
              <Route path="/home" element={<Home />} />
              <Route path="/migration" element={<Migration />} />

              <Route path="/farming" element={<Staking />} />
              <Route path="/farming-details" element={<StakingDetails />} />
              <Route path="/farming/fetch-stake" element={<StakingFetch />} />

              <Route path="/vesting" element={<Vesting />} />
              <Route path="/vesting/vesting-details/:beneficiary" element={<VestingDetails/>} />
              <Route path="/vesting/add-vesting" element={<AddVesting />} />

              <Route path="/affiliate/referral-requests" element={<Referral />} />
              <Route path="/affiliate/referral-requests-details" element={<ReferralDetails />} />

              <Route path="/affiliate/referral-reward" element={<ReferalReward />} />
              <Route path="/affiliate/referral-reward-details" element={<ReferalRewardDetails />} />

              <Route path="/affiliate/leaderboard-matrix" element={<LeadershipBoard />} />
              <Route path="/affiliate/leaderboard-matrix-details" element={<LeadershipBoardDetails />} />

              <Route path="/affiliate/leaderboard-rank" element={<LeadershipRank />} />
              <Route path="/affiliate/leaderboard-rank-details" element={<LeadershipRankDetails />} />

              <Route path="/affiliate/personal-invite" element={<Direct />} />
              <Route path="/affiliate/personal-invite-details" element={<DirectDetails />} />

              <Route path="/affiliate/secondary-invite" element={<Indirect />} />
              <Route path="/affiliate/secondary-invite-details" element={<InDirectDetails />} />

              <Route path="/dao" element={<Dao />} />
              <Route path="/dao-details" element={<DaoDetails />} />

              <Route path="/blogs" element={<Blogs />} />

              <Route path="/news" element={<News />} />
              <Route path="news/add-news" element={<AddNews />} />
              <Route path="news/news-details" element={<NewsDetails />} />
              <Route path="news/news-video" element={<VideoNews />} />
              <Route path="news/edit-news-details" element={<EditTextArticle />} />
              <Route path="news/edit-youtube-news-details" element={<EditVideoArticles />} />

              <Route path="/changepassword" element={<ChangePassword />} />
            </Route>
          </Route>
        </Routes>
      </UserContextProvider>
    </>
  );
}

export default App;
