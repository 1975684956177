import React from 'react';
import './Loader.css';

const LoaderVesting = () => {
  return (
    <div className="loader-vest-cont">
      <div className="loader-vest"></div>
    </div>
  );
};

export default LoaderVesting;
